import React from "react"
// import style
import "./get-started-footer.css"
//import icons
import IconRightArrow from "./../assets/icons/svg/arrow-pointing-to-right.svg"
import { OutboundLink } from "gatsby-plugin-google-gtag"
/**
 * SIGNUP BANNER > GET STARTED NOW
 */
const GetStartedBanner = () => {
  return (
    <section className="u-signup-banner">
      <div className="container">
        <h2 className="u-signup-banner__title">It's time to get started</h2>
        <div className="u-signup-banner__subtitle">
          Odown is the premium-style website uptime monitoring tool you need to
          cover your backend. At the same time, you focus on growing your
          business.
        </div>
        <div className="u-signup-banner__subtitle2">
          Start your free 14-day trial. No credit card is required.
        </div>
        <div className="u-signup-banner__button">
          <OutboundLink
            className="btn-primary"
            eventCategory="Launch the Odown App"
            eventAction="Click"
            eventLabel="Goto signup"
            href="https://app.odown.io/signup"
          >
            Get Started Free <IconRightArrow />
          </OutboundLink>
        </div>
      </div>
    </section>
  )
}

export default GetStartedBanner
